/*
 * @Descripttion:
 * @version:
 * @Author: captern@icloud.com
 * @Date: 2022-07-07 17:56:38
 * @LastEditors: captern
 * @LastEditTime: 2022-07-26 13:32:51
 */
import React from "react";
import "./App.css";
import Home from "./views/Home/Home";

const App: React.FC = () => {
  return (
    <div className="App">
       <Home />
    </div>
  );
};

export default App;
