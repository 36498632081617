import React from "react";
import {
    OrbitControls,
    Environment,
    PerspectiveCamera,
    Effects,
} from "@react-three/drei";
import { useFrame, useThree,Canvas } from "@react-three/fiber";
import * as THREE from "three"
import { models } from "./data";
import {
    Bloom,
    DepthOfField,
    EffectComposer,
    Noise,
    Vignette,
} from "@react-three/postprocessing";
// a texture for points material
// use html canvas
const CircleImg = (() => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as  CanvasRenderingContext2D;
    const size = 256;
    canvas.width = size;
    canvas.height = size;
    context.fillStyle = "white";
    context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
    context.fill();
    return canvas;
})();
const texture = new THREE.CanvasTexture(CircleImg);
const speed = 0.1;
const Model = ({ value, index, position } :{
    value:Float32Array,
    index:number,
    position:any[]
}) => {
    const modelRef = React.useRef<any>();

    useFrame(({ clock }) => {
        const time = clock.getElapsedTime();
        modelRef.current.rotation.x = Math.sin(time / 2) * 0.1 -Math.PI / 2;
        modelRef.current.rotation.y = Math.cos(time / 2) * 0.1;
    });
    return (
        <points
            ref={modelRef}
            scale={2}
            position={position as unknown as THREE.Vector3}
            key={index}
            
        >
            <bufferGeometry>
                <bufferAttribute
                    attach="attributes-position"
                    array={value as unknown as THREE.TypedArray}
                    count={value.length / 3}
                    itemSize={3}
                />
            </bufferGeometry>
            <pointsMaterial
                // map={CircleImg}

                color={"#ffffff"}
                size={0.01}
                sizeAttenuation //merupakan parameter yang menscale object berdasarkan perspective camera
                transparent={true}
                map={texture}
            />
        </points>
    );
};

const Scene = () => {
    // const positions = models[0]; // an array of positions
    //scroll to zoom
    const groupRef = React.useRef<any>();
    const { camera } = useThree();
    useFrame(({ clock, mouse }) => {
        const group = groupRef.current;
        const children = group.children;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.position.z > 10) {
                group.remove(child);
                child.position.z = -40 * children.length-30;
                group.add(child);
            }
            child.position.z += speed;
        }


        camera.position.lerp(
            new THREE.Vector3(-mouse.x * 6, mouse.y * 5, camera.position.z),
            0.05
        );
    });
    React.useEffect(() => {
        const handleScroll = (e:any) => {
            const delta = e.wheelDelta;
            const group = groupRef.current;
            const children = group.children;
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (delta > 0) {
        
                    child.position.z -= 0.7;
                } else {
                    child.position.z += 0.7;
                }
            }
        };
        window.addEventListener("wheel", handleScroll);
        return () => {
            window.removeEventListener("wheel", handleScroll);
        };
    }, [camera]);

    return (
        <>
            <fog attach={"fog"} color={"#000000"} near={30} far={75} />

            <group ref={groupRef}>
                {models.map((value, index) => (
                    <Model
                        key={index}
                        value={value as unknown as Float32Array}
                        index={index}
                        position={[index % 2 ? 8 : -8, -10, index * -40 - 10]}
                    />
                ))}
            </group>

            {/* <Rig /> */}
            <PerspectiveCamera
                makeDefault
                position={[0, 0, 0]}
                fov={75}
                near={0.1}
                far={100}
            />
            {/* <OrbitControls /> */}
           
        </>
    );
};

function ThreeApp() {
    return (
        <Canvas style={{ backgroundColor: "black" ,height:"80vh",width:'80%', marginTop:200,position:'absolute',left: '10%',bottom:'10%'}}>
            <Scene />
            <EffectComposer>
                <Bloom
                    luminanceThreshold={0.9}
                    luminanceSmoothing={0.4}
                    height={400}
                    intensity={1.5}
                    opacity={0.9}
                />
            </EffectComposer>
        </Canvas>
    );
}

export default ThreeApp;

